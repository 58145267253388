import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import React from "react";
import myTheme from "./themes/mui-theme";




function SelectComp({onChangeFunc, inputProps, label, menuItems}) {

    return (

    <ThemeProvider theme={myTheme}>
        <Box sx={{
            '& > :not(style)': {
                m: 1,
                width: '25ch',
                color: myTheme.palette.secondary.main,
                backgroundColor: myTheme.palette.background.main,
            },
            '& .MuiSelect-icon': {
                color: myTheme.palette.primary.main, // Set arrow color to match primary color
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: myTheme.palette.primary.main, // Set outline color to match primary color
                },
                '&:hover fieldset': {
                    borderColor: myTheme.palette.primary.main, // Set outline color on hover to match primary color
                },
                '&.Mui-focused fieldset': {
                    borderColor: myTheme.palette.primary.main, // Set outline color when focused to match primary color
                },
            },
        }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"
                            style={{color: myTheme.palette.primary.main}}>
                    {label}
                </InputLabel>
                <Select
                    label={label}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(event) => onChangeFunc(event)}
                    sx={{color: "white"}}>

                    {menuItems && Object.keys(menuItems).map((value, index) => (
                        <MenuItem key={index} value={value}>{value}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    </ThemeProvider>
)
}
export default SelectComp;