import React from "react";

const SuccessMessage = ({ message }) => {
    return (
        <div className="success-box">
            {message}
        </div>
    );
};

export default SuccessMessage;