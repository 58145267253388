import React, { useState } from 'react';
import "../../App.css"
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import axios from "axios";
import baseLink from "../data/api-base-link";






function Logout({setError, setSuccess ,token, setToken }) {

    const handleLogout = async () => {
        try {
            setSuccess("Logging out...")
            const response = await axios.get(`https://${baseLink}/logout/` + token);
            if (response.status === 200) {
                setToken(null)
                // Remove token from localStorage
                localStorage.removeItem('loginToken');
                setError(""); // Clear any previous error message
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Invalid username or password.");
                } else if (error.response.status === 500) {
                    setError("Server error. Please try again later.");
                }
            } else {
                setError("An unexpected error occurred.");
            }
        }
    };


    return (
        <div className={"PageDefaults"}>
            <div className={"logout"}>
                <div className={"BoxItem"} style={{paddingBottom: "2ch", paddingRight: "2ch", paddingLeft: "2ch"}}>
                    <div>
                        <button onClick={handleLogout}>
                            Confirm Logout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Logout;