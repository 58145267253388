import React, { useState, useEffect, useRef } from 'react';
import { Box, ThemeProvider, TextField } from '@mui/material';
import myTheme from "./themes/mui-theme";

const CustomTextField = ({ value, onChange, colors }) => {
    const [content, setContent] = useState([
        { text: value, color: 'red' }
    ]);

    const handleInput = (e) => {
        const newContent = e.target.innerHTML.split('<br>').map((line, index) => ({
            text: line,
            color: content[index] ? content[index].color : 'black'
        }));
        setContent(newContent);
    };


    return (
        <div>
            <div className={"custom-text-field"}
                style={{
                    border: `2px solid ${myTheme.palette.primary.main}`,
                    padding: "14px",
                    borderRadius: "4px",
                    maxWidth: "100%",
                    minHeight: "100px",
                    maxHeight: '150px',
                    overflowX: "hidden",
                    overflowY: "auto",
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    color: 'white',
                    boxSizing: 'border-box',
                    backgroundColor: myTheme.palette.background.main,
                    outline: 'none',
                    transition: 'border-color 0.2s ease-in-out',
                    cursor: 'text',
                }}
                contentEditable="true"
                onInput={handleInput}

            >
            </div>
        </div>
    );
};

export default CustomTextField;
