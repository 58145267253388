import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import React from "react";
import myTheme from "./themes/mui-theme";



function TextFieldComp({onChangeFunc, inputProps, type, placeholder, label, isMultiline, value, style}) {

    return (
    <ThemeProvider theme={myTheme}>
        <Box
            component="form"
            sx={{
                '& > :not(style)': {
                    m: 1,
                    width: '25ch',
                    color: "white",
                    backgroundColor: myTheme.palette.background.main,
                },
                '& input': {
                    color: 'white', // Set text color to white
                },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                    },
                    '&:hover fieldset': {
                        borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                    },
                },
            }}
            noValidate
            autoComplete="off">
            {isMultiline ? (
                <TextField type={type} placeholder={placeholder} multiline value={value}
                           onChange={(event) => onChangeFunc(event)}
                           color="primary" label={label} variant="outlined" inputProps={inputProps}
                           focused style={style} />
            ) : (
                <TextField type={type} placeholder={placeholder} value={value}
                           onChange={(event) => onChangeFunc(event)}
                           color="primary" label={label} variant="outlined" inputProps={inputProps}
                           focused style={style}/>
            )}

        </Box>
    </ThemeProvider>
    );
}

export default TextFieldComp;