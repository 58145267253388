import React, {useEffect, useRef, useState} from 'react';
import "../../App.css"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ThemeProvider } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import axios from "axios";
import { saveAction, getActions, clearActions } from '../utils/localStorageUtils';
import TextFieldComp from "../TextFieldComponent";
import myTheme from "../themes/mui-theme";
import baseLink from "../data/api-base-link";

// Function to request data from the server


// Function to load data





function BuyKeys({gamedata, token, setToken, setError, setSuccess, discount, balance, setBalance}) {
    // rendering logic for middle section where you select a game based off the product
    const [selectedGame, setSelectedGame] = useState(null); // state to hold selected game for dropdown menu
    const [amountDict, setAmountDict] = useState({}); // State to hold the values of text fields
    const [searchFieldValue, setSearchFieldValue] = useState(""); // state to hold search field request
    const [keyValue, setKeyValue] = useState(""); // State to hold value of key for HWID reset or Key Freeze
    const [reasonValue, setReasonValue] = useState(""); // State to hold reason of request above
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [paymentPending, setPaymentPending] = useState(false);
    const [orderId, setOrderID] = useState(null);
    const [paymentLink, setPaymentLink] = useState("");
    const [total, setTotal] = useState(0.);
    const [paymentAddress, setPaymentAddress] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");
    const historyListRef = useRef(null);
    const [userBal, setUserBal] = useState(balance);

    const actions = getActions();

    useEffect(() => {
        const handleScroll = () => {
            const list = historyListRef.current;
            if (list) {
                const rows = list.querySelectorAll('.row');
                rows.forEach(row => {
                    const rect = row.getBoundingClientRect();
                    const listRect = list.getBoundingClientRect();

                    // Calculate visible height within the list viewport
                    const visibleHeight = Math.min(rect.bottom, listRect.bottom) - Math.max(rect.top, listRect.top);

                    // Ensure the visible height is within valid bounds
                    const clampedVisibleHeight = Math.max(0, Math.min(rect.height, visibleHeight));

                    // Calculate visibility percentage
                    const visibilityPercentage = clampedVisibleHeight / rect.height;

                    // Apply styles based on visibility
                    if (visibilityPercentage > 0 && visibilityPercentage < 1) {
                        row.style.opacity = visibilityPercentage.toString();
                        row.style.transform = `scale(${visibilityPercentage})`;
                        row.style.visibility = 'visible';
                    } else if (visibilityPercentage <= 0) {
                        row.style.opacity = '0';
                        row.style.transform = 'scale(0)';
                        row.style.visibility = 'hidden'; // Hide the row completely
                    } else {
                        row.style.opacity = '1';
                        row.style.transform = 'scale(1)';
                        row.style.visibility = 'visible';
                    }
                });
            }
        };

        const list = historyListRef.current;
        if (list) {
            list.addEventListener('scroll', handleScroll);
            // Call handleScroll initially to set the correct state on load
            handleScroll();
        }

        return () => {
            if (list) {
                list.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const handleSaveAction = (key, actionDone, reason) => {
        const action = { key, actionDone, reason };
        saveAction(action);
        // Clear inputs after saving
    };

    const handlePurchase = () => {
        setPaymentPending(false)
        setPaymentLink("")
        setPaymentAddress("")
        setOrderID("")
        setPaymentMethod("")
        if (!selectedGame) {
            setError("Please select game and amount")
            return
        }
        if (!amountDict) {
            setError("Please select amount of keys to purchase")
            return
        }
        const hasPositiveValue = Object.values(amountDict).some(value => value > 0);
        if (!hasPositiveValue) {
            setError("You must buy at least 1 key")
            return
        }
        setIsPopupOpen(!isPopupOpen);
    };

    const handlePaymentMethod = async (method) => {
        setPaymentPending(true)
        // send payment request and handle payment details
        try
        {
            switch (method) {
                case "balance":
                    if (userBal < total) {
                        setError("Not enough balance")
                        setIsPopupOpen(false)
                        return
                    }
                    setPaymentMethod("balance")
                    const balresponse = await axios.get(`https://${baseLink}/order/${token}/balance/${selectedGame}`, {
                        params: amountDict
                    });
                    if (balresponse.status === 200) {
                        setSuccess("Order received, being processed.")
                        if (balresponse && balresponse.data && balresponse.data.oid && balresponse.data.ototal) {
                            setUserBal(parseFloat(userBal) - parseFloat(total))
                            setOrderID(balresponse.data.oid)
                            setTotal(balresponse.data.ototal)

                        }
                    }

                    break;


                case "hoodpay":
                    setPaymentMethod("hoodpay")
                    const hpresponse = await axios.get(`https://${baseLink}/order/${token}/hoodpay/${selectedGame}`, {
                        params: amountDict
                    });
                    if (hpresponse.status === 200) {
                        if (hpresponse.data && hpresponse.data.oid && hpresponse.data.olink) {
                            setUserBal(parseFloat(userBal) - parseFloat(total))
                            setOrderID(hpresponse.data.oid)
                            setPaymentLink(hpresponse.data.olink)
                            setTotal(hpresponse.data.ototal)
                        }
                    }
                    break;
                case "paypal":
                    setPaymentMethod("paypal")
                    const ppresponse = await axios.get(`https://${baseLink}/order/${token}/paypal/${selectedGame}`, {
                        params: amountDict
                    });
                    if (ppresponse.status === 200) {
                        if (ppresponse.data && ppresponse.data.oid && ppresponse.data.paypal && ppresponse.data.ototal) {
                            setUserBal(parseFloat(userBal) - parseFloat(total))
                            setOrderID(ppresponse.data.oid)
                            setPaymentAddress(ppresponse.data.paypal)
                            setTotal(ppresponse.data.ototal)
                        }
                    }
                    break;
                case "cashapp":
                    setPaymentMethod("cashapp")
                    const caresponse = await axios.get(`https://${baseLink}/order/${token}/cashapp/${selectedGame}`, {
                        params: amountDict
                    });
                    if (caresponse.status === 200) {
                        if (caresponse.data && caresponse.data.oid && caresponse.data.cashtag && caresponse.data.ototal) {
                            setUserBal(parseFloat(userBal) - parseFloat(total))
                            setOrderID(caresponse.data.oid)
                            setPaymentAddress(caresponse.data.cashtag)
                            setTotal(caresponse.data.ototal)
                        }

                    } break;
                default:
                    setError("An unexpected error occured.")
                    break;


            }
        }
        catch (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        setError("Token expired, please login again.");
                        setToken(null);

                    } else if (error.response.status === 500) {
                        setError("Server error. Please try again later.");
                    }
                    else if (error.response.status === 429) {
                        setError("Too many requests, please wait 1 minute.");
                    }
                    else if (error.response.status === 400) {
                        setError(error.response.data);
                    }
                } else {
                    setError("An unexpected error occurred.");
                }
            }
    }




    const handleHWIDReset = async () => {
        try {
            if (keyValue === "") {
                setError("Must input a Key for HWID Reset")
                return
            }
            if (reasonValue === "") {
                setError("Must put a Reason for HWID Reset")
                return
            }
            const response = await axios.get(`https://${baseLink}/key/reset/` + keyValue + "/" + reasonValue + "/" + token + "?nocache=");
            if (response.data) {
                if (!(response.data.startsWith("Key not found"))) {
                    handleSaveAction(keyValue,"HWID Reset", reasonValue)
                }
                setSuccess(response.data)
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Invalid username or password.");
                } else if (error.response.status === 500) {
                    setError("Server error. Please try again later.");
                } else if (error.response.status === 429) {
                    setError("Too many requests, please wait 1 minute.");
                } else if (error.response.status === 400) {
                    setError(error.response.data);
                } else {
                    setError("An unexpected error occurred.");
                }
            }
        }
    };

    const handleKeyFreeze = async () => {
        try {
            if (keyValue === "") {
                setError("Must input a key for Key Freeze")
                return
            }
            if (reasonValue === "") {
                setError("Must input a Reason for Key Freeze")
                return
            }
            const response = await axios.get(`https://${baseLink}/key/freeze/` + keyValue + "/" + reasonValue + "/" + token + "?nocache=");
            if (response.data) {
                if (!(response.data.startsWith("Key not found"))) {
                    handleSaveAction(keyValue,"Key Frozen", reasonValue)
                }
                setSuccess(response.data);
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Invalid username or password.");
                } else if (error.response.status === 500) {
                    setError("Server error. Please try again later.");
                } else if (error.response.status === 429) {
                    setError("Too many requests, please wait 1 minute.");
                }
            } else {
                setError("An unexpected error occurred.");
            }
        }
    };

    const handleGameSelection = (selectedGame) => {
        setSelectedGame(selectedGame);

        if (selectedGame==="balance") {
            setAmountDict({balance:0})
        }


        // Check if the selected game exists in gamedata
        if (gamedata[selectedGame] && gamedata[selectedGame].lengths) {
            // Update the dictionary with new values for the selected game
            const newDict = {};
            Object.keys(gamedata[selectedGame].lengths).forEach((length) => {
                // Check if the length already exists in the existing dictionary
                // If it does, keep the existing value; otherwise, set it to 0
                newDict[length] = amountDict[length] || 0;
            });
            setAmountDict(newDict);
        }
    };

    const setBalDict = (value) => {
        // Remove leading zeros (except for "0.")
        value = value.replace(/^0+(?!\.|$)/, '');

        // Ensure only one period is allowed
        const parts = value.split('.');
        if (parts.length > 2) {
            // If there are multiple periods, join all parts except the last one
            value = parts.slice(0, 2).join('.') + parts.slice(2).join('');
        }

        // Allow empty input to set balance to 0
        if (value === "") {
            setAmountDict({ Balance: 0 });
            setTotal(0);
            return;
        }

        // Check if the value contains a valid float
        let balValue = parseFloat(value);

        // Check if the parsed value is NaN or negative
        if (isNaN(balValue) || balValue < 0) {
            balValue = 0;
        }

        // Update the state with the new amountDict
        setAmountDict({ Balance: value }); // Keep the string value for input
        setTotal(balValue); // Set the parsed float value for calculations
    };



    const setDict = (length, value) => {
        // Parse the value as an integer
        let intValue = parseInt(value);

        // Check if the parsed value is NaN or negative
        if (isNaN(intValue) || intValue < 0) {
            // If NaN or negative, set the value to 0
            intValue = 0;
        }

        // Create a new amountDict with the updated value
        const newAmountDict = {
            ...amountDict,
            [length]: intValue
        };

        // Update the state with the new amountDict
        setAmountDict(newAmountDict);

        // Calculate total cost of all keys based on the new amountDict
        const gameLengths = gamedata[selectedGame].lengths;
        let totalCost = 0;

        for (const [length, amount] of Object.entries(newAmountDict)) {
            const price = parseFloat(gameLengths[length]);
            if (!isNaN(price)) {
                totalCost += price * amount;
            }
        }
        totalCost = totalCost * (1-discount)

        setTotal(totalCost.toFixed(2)); // to keep the cost to two decimal places
    };

    const handleKeyFieldChange = (event) => {
        setKeyValue(event.target.value);
    };

    const handleReasonChange = (event) => {
        setReasonValue(event.target.value);
    };


    const handleSearchFieldChange = (event) => {
        setSearchFieldValue(event.target.value);
    };

    let keyHistory = {}

    // rendering logic for middle section where you select a game based off the product






    return (
        <div className="PageDefaults">
            {isPopupOpen && (
                <div className="popup">
                    <div className="popup-content">
                        {
                            paymentPending ? (

                                // Render content for completed payment
                                <>
                                    <span className="close-btn" onClick={handlePurchase}>&times;</span>

                                    {/* Always display the total and order ID */}
                                    <div style={{marginTop: "2ch", marginBottom: "2ch"}}><em>Order ID:</em> {orderId}</div>
                                    <div className={"pendingOrderFlexbox"}>
                                        <div className={"pendingOrderInfo"}>

                                            <div><em>Method:</em> {paymentMethod.charAt(0).toUpperCase() + paymentMethod.slice(1)}</div>
                                            {/* Conditionally render based on paymentAddress */}
                                            {paymentAddress && (
                                                <div><em>Send to:</em> {paymentAddress}</div>
                                                // This displays the payment address as text
                                            )}
                                            <div><em>Total:</em> $ {total}</div>

                                        </div>
                                        <div className={"pendingOrderPayment"}>
                                            {paymentMethod === "paypal" ? (
                                                <p>Send as Friends and Family ONLY.</p>
                                            ) : paymentMethod === "cashapp" ? (
                                                <p>The Order ID MUST be included in the cashapp note, case sensitive.</p>
                                            ) : paymentMethod === 'hoodpay' ? (
                                                <p>Click the button below to pay.</p>
                                            ) : (
                                                <p>Order will be sent via DM.</p>
                                            )}
                                        </div>
                                    </div>

                                    {/* Conditionally render based on paymentLink */}
                                    {paymentLink && (
                                        <button onClick={() => window.open(paymentLink, '_blank')}>Pay Now</button>
                                        // This button opens the payment link in a new tab when clicked
                                    )}



                                </>
                            ) : (
                                <>
                                <span className="close-btn" onClick={handlePurchase}>&times;</span>
                                    <h2>Select Payment Method</h2>
                                    <div className="buttons">
                                        <button onClick={() => handlePaymentMethod("hoodpay")}>Crypto</button>
                                        <button onClick={() => handlePaymentMethod("cashapp")}>Cashapp</button>
                                        <button onClick={() => handlePaymentMethod("paypal")}>Paypal</button>
                                        {!(selectedGame === "balance") && (
                                            <button onClick={() => handlePaymentMethod("balance")}>Balance</button>
                                        )}

                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            )}
            <div className={"BoxItemFlexBox"}>
            <div className="BuyKeys">
                    <div className="InfoSection">
                        <h2>Select Product</h2>
                        <div className="BoxItem">
                            <div>

                                <p>Select Product</p>

                                <ThemeProvider theme={myTheme}>
                                    <Box sx={{
                                        '& > :not(style)': {
                                            m: 1,
                                            width: '25ch',
                                            color: myTheme.palette.secondary.main,
                                            backgroundColor: myTheme.palette.background.main,
                                        },
                                        '& .MuiSelect-icon': {
                                            color: myTheme.palette.primary.main, // Set arrow color to match primary color
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color to match primary color
                                            },
                                            '&:hover fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color on hover to match primary color
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color when focused to match primary color
                                            },
                                        },
                                    }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label"
                                                        style={{color: myTheme.palette.primary.main}}>
                                                {"Select Game"}
                                            </InputLabel>
                                            <Select
                                                label={"Select Game"}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                onChange={(event) => handleGameSelection(event.target.value)}
                                                sx={{color: "white"}}>
                                                <MenuItem key={999} value={"balance"}>Balance</MenuItem>
                                                {gamedata && Object.keys(gamedata).map((game, index) => (
                                                    <MenuItem key={index} value={game}>{game}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </ThemeProvider>

                                {selectedGame && selectedGame === "balance" && (
                                    <div className={"TextBoxes"}>
                                        <p>Balance Topup</p>
                                        <ThemeProvider theme={myTheme}>
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& > :not(style)': {
                                                        m: 1,
                                                        width: '25ch',
                                                        color: "white",
                                                        backgroundColor: myTheme.palette.background.main,
                                                    },
                                                    '& input': {
                                                        color: 'white', // Set text color to white
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: myTheme.palette.primary.main,
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: myTheme.palette.primary.main,
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: myTheme.palette.primary.main,
                                                        },
                                                    },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <TextField
                                                    type="text"
                                                    onChange={(event) => setBalDict(event.target.value.replace(/^0+/, ''))}
                                                    color="primary"
                                                    label="Amount"
                                                    variant="outlined"
                                                    placeholder={"Topup Amount"}
                                                    focused
                                                    value={amountDict["Balance"]}
                                                    inputProps={{ min: "0", maxLength: 7 }}
                                                />
                                            </Box>
                                        </ThemeProvider>
                                    </div>
                                )}

                                {gamedata && gamedata[selectedGame] && gamedata[selectedGame].status && (
                                    <p><em>Status:</em> {gamedata[selectedGame].status}</p>
                                )}

                                {selectedGame && gamedata[selectedGame] && gamedata[selectedGame].lengths &&
                                    Object.keys(gamedata[selectedGame].lengths).map((length, index) => {
                                        return (

                                            <div>
                                                <p>{length.charAt(0).toUpperCase() + length.slice(1)}</p>
                                                <ThemeProvider theme={myTheme}>
                                                    <Box
                                                        component="form"
                                                        sx={{
                                                            '& > :not(style)': {
                                                                m: 1,
                                                                width: '25ch',
                                                                color: "white",
                                                                backgroundColor: myTheme.palette.background.main,
                                                            },
                                                            '& input': {
                                                                color: 'white', // Set text color to white
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                                },
                                                            },
                                                        }}
                                                        noValidate
                                                        autoComplete="off"
                                                    >
                                                        <TextField
                                                            type="text" // Change type to "text"
                                                            onChange={(event) => setDict(length, event.target.value.replace(/^0+/, ''))} // Remove leading zeros
                                                            color="primary"
                                                            label={"Amount"}
                                                            variant="outlined"
                                                            focused
                                                            value={amountDict[length]} // Display the value as is from the state
                                                            inputProps={{ min: "0", maxLength:7}}
                                                        />
                                                    </Box>
                                                </ThemeProvider>

                                            </div>
                                        );
                                    })}


                            </div>
                        </div>
                    </div>
                </div>
                <div className="KeyManage">
                    <div className={"InputKey"}>
                        <h2>Key Actions</h2>
                        <div className="BoxItem">
                            <div className="TextFieldBox">
                                <div>
                                    <p>Enter Key and Reason</p>
                                    <TextFieldComp type={"text"} onChangeFunc={handleKeyFieldChange} placeholder={"Required"} label={"Product Key"} />
                                </div>
                                <div>
                                    <ThemeProvider theme={myTheme}>
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': {
                                                    m: 1,
                                                    width: '25ch',
                                                    color: "white",
                                                    backgroundColor: myTheme.palette.background.main,
                                                },
                                                '& input': {
                                                    color: 'white', // Set text color to white
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                    },
                                                },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField type="text" onChange={(event) => handleReasonChange(event)}
                                                       color="primary" label={"Reason"} variant="outlined" placeholder={"Required"}
                                                       focused/>
                                        </Box>
                                    </ThemeProvider>
                                </div>
                                <div>
                                    <button onClick={handleHWIDReset}>
                                    HWID Reset
                                    </button>
                                </div>
                                <div>
                                    <button onClick={handleKeyFreeze}>
                                        Freeze Key
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h2 style={{paddingTop: "1ch"}}>Order Details</h2>
                            <div className={"BoxItem"}>
                                <div className={"orderDetailsFlexbox"}>
                                    <div>
                                        <p><em>Discount:</em> {discount * 100} %</p>
                                        <p><em>Total:</em> $ {total}</p>
                                        <p><em>Balance:</em> $ {userBal}</p>
                                        <button onClick={handlePurchase}>
                                            Purchase
                                        </button>
                                    </div>
                                    <div className={"cost"}>
                                        {Object.entries(amountDict).map(([key, val]) => {
                                        return (
                                            <p key={key}>
                                                <em>{key}:</em> {val}
                                            </p>
                                        );
                                    })}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={"ActionHistory"}>
                    <div className={"SortedActions"}>
                        <h2>Action Log</h2>
                        <div className={"BoxItem"}>
                            <div>
                                <p>Search for Action</p>
                                <ThemeProvider theme={myTheme}>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': {
                                                m: 1,
                                                width: '95%',
                                                color: "white",
                                                backgroundColor: myTheme.palette.background.main,
                                            },
                                            '& input': {
                                                color: 'white', // Set text color to white
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                },
                                            },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <TextField type="text" onChange={(event) => handleSearchFieldChange}
                                                   color="primary" label={"Key or Action"} variant="outlined" placeholder={"Search"}
                                                   focused/>
                                    </Box>
                                </ThemeProvider>
                                </div>
                            <div className={"HistoryResults"}>
                                <ul className={"HistoryList"} ref={historyListRef}>
                                    {searchFieldValue && keyHistory[searchFieldValue]}
                                    {keyHistory ? (
                                        <>
                                            {actions && actions.map((action, index) => (
                                                <li key={action.key} className="row" id={index}>
                                                    <div id="title">
                                                        <p>
                                                            {action.key}<br/>
                                                            {action.actionDone} <br/>
                                                            {action.reason} <br/>
                                                        </p>
                                                    </div>
                                                </li>
                                            ))}
                                        </>

                                    ) : (
                                        <>
                                        {!Object.keys(keyHistory).length && (
                                        <li className="row" id={0}>
                                    <div id="title">
                                        <p>
                                            You have no action history.
                                        </p>
                                    </div>
                                </li>
                                )}
                                      </>
                                    )}


                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default BuyKeys;